import React, { useState, useEffect } from "react";
import "./PortalStyle.css";
import icon from "./img/icon@2x.svg"
import iconCopy from "./img/icon-copy@2x.svg"
import icon1 from "./img/icon-2@2x.svg"
import icon2 from "./img/icon-1@2x.svg"
import iconMail from "./img/icon-email@2x.svg"
import creditLogo from "./img/credit-logo.png"
import moment from 'moment'
import { Spinner } from "react-bootstrap";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';
import * as customQueries from '../graphql/customQueries'
import CreditsAvailable from "./CreditsAvailable";

function MemberCard(props) {

    const artboard52X5 = "https://anima-uploads.s3.amazonaws.com/projects/6293d6af26f8364d4373cf96/releases/6293d79a9a9d18d6b6125f96/img/artboard-5-2x-5@4x.png"
    const height = window.innerHeight;
    const width = window.innerWidth;
    const {member} = props;

    const link = window.location.href
    const splitUrl = link.split("/")
    const linkEnd = splitUrl[splitUrl.length - 1]

    const [merchantName, setMerchantName] = useState("")
    const [username, setUsername] = useState("")
    const [membershipID , setMembershipID] = useState("")
    const [reloadDate, setReloadDate] = useState("")
    const [memberSinceDate, setMemberSinceDate] = useState("")
    const [planName, setPlanName] = useState("")
    const [merchantID, setMerchantID] = useState("")
    const [creditDisplay, setCreditDisplay] = useState("")
    const [creditsAvailable, setCreditsAvailable] = useState(0)

    function generateReload(newMealDOM) {
        const month = moment().month() + 1
        const day = moment().date()
        const year = moment().year()
        console.log(`newMealDOM: ${newMealDOM}`)
        console.log(`month: ${month}`)
        console.log(`day: ${day}`)
        console.log(`year: ${year}`)
        let end = `${newMealDOM}th of month`

        if (newMealDOM <= day) {
            if (month === 12) {
            end = moment(`${1}-${newMealDOM}-${year + 1}`, "MM-DD-YYYY").format("MMMM Do")
            } else if (moment(`${month + 1}-${newMealDOM}-${year}`, "MM-DD-YYYY").month() === month) {
            end = moment(`${month + 1}-${newMealDOM}-${year}`, "MM-DD-YYYY").format("MMMM Do")
            } else {
            end = moment(`${month + 1}-${year}`, "MM-YYYY").endOf('month').format("MMMM Do")
            }
        } else {
            if (moment(`${month}-${newMealDOM}-${year}`, "MM-DD-YYYY").month() + 1 === month) {
            end = moment(`${month}-${newMealDOM}-${year}`, "MM-DD-YYYY").format("MMMM Do")
            } else {
            end = moment(`${month}-${year}`, "MM-YYYY").endOf('month').format("MMMM Do")
            }
        }
        return `${end}`
    }

    function generateMemberSince(createdAt) {
        const str = moment(createdAt, "YYYY-MM-DDTHH:mm:ss.SSSZ").format('MMM YYYY')
        return `${str}`
    }

    function generatePlanTier(planTier) {
    if (planTier === 3) {
        return 'Gold'
    } else if (planTier === 2) {
        return 'Silver'
    } else {
        return 'Bronze'
    }
    }

    function generateChangeLink(merchantID) {
    switch (merchantID) {
        // Chevron
        case 'Q7Z5A19D1A7D1':
        return "https://form.jotform.com/213050471551142"
        // Shell
        case 'WQZS2Z0QX1CM1':
        return "https://form.jotform.com/213050454188149"
        // Braxton's
        case 'AGZVK2WRCKQM1':
        return "https://form.jotform.com/213048951065051"
        // Test
        case 'TestMerchant':
        return "https://form.jotform.com/213048951065051"
        // Nourish
        case 'NOURISH':
        return "https://form.jotform.com/213145600160137"
        case 'BRAXTONSCAFE':
        return "https://form.jotform.com/220387977002155"
        // Big Shakes
        case 'BIGSHAKES':
        return "https://form.jotform.com/220820742628151"
        // Array of Sun
        // Bahama Golf
        case 'BAHAMAGOLF':
        return "https://form.jotform.com/221110614842140"
        case 'TOSSITUP':
        return "https://form.jotform.com/213161297814153"
        case 'VERITABLE':
        return "https://form.jotform.com/221358951057156"
        case 'ARRAYOFSUN':
        return "https://form.jotform.com/221358756885168"
        case 'BIGSHAKES11TH':
        return "https://thechewcrew.co/bigshakeshotchicken"
        case 'DINGHY':
        return "https://form.jotform.com/231643512704146"
        case 'MVLIQUIDATION':
        return "https://form.jotform.com/232016988382160"
        case 'FORGOTTENSTAR':
        return "https://form.jotform.com/232215640993154"
        case 'RISECAFE':
        return "https://form.jotform.com/232420486599162"
        case 'SOMMELIER':
        return "https://form.jotform.com/232481113389153"
        default:
        return "https://form.jotform.com/213048951065051"
    }
    }

    function unlimited(merchantID) {
    switch(merchantID) {
        case 'VERITABLE':
        return true
        case 'BAHAMAGOLF':
        return true
        case 'TestMerchant':
        return false
        default:
        return false
    }
    }

    function creditDisplayFromMember(member) {
    var displayString = ``
    member.credits.items.forEach((credit) => {
        var component = member.plan.components.items.filter(component =>
        component.creditTypeComponentId === credit.creditCreditTypeId)[0]
        if (component.unlimited === true) {
        displayString+=`Unlimited ${credit.creditType.name} Credit(s)\n`
        } else {
        displayString+=`${credit.creditsAvailable} ${credit.creditType.name} Credits \n`
        }
    })
    return displayString
    }

    function creditLines(member) {
    return member.credits.items.map((credit) => <CreditsAvailable member={member} credit={credit}/>)
    }

    function populateMemberData() {
        setMerchantName(member.merchant.name)
        setUsername(member.username)
        setMembershipID(member.membershipID)
        setReloadDate(generateReload(member.signUpDOM))
        setMemberSinceDate(generateMemberSince(member.createdAt))
        setPlanName(member.plan.name)
        setMerchantID(member.merchant.merchantID)
        setCreditDisplay(creditDisplayFromMember(member))
        setCreditsAvailable(member.credits.items[0].creditsAvailable)
    }


    useEffect(() => {
        setMerchantName(member.merchant.name)
        setUsername(member.username)
        setMembershipID(member.membershipID)
        setReloadDate(generateReload(member.signUpDOM))
        setMemberSinceDate(generateMemberSince(member.createdAt))
        setPlanName(member.plan.name)
        setMerchantID(member.merchant.merchantID)
        setCreditDisplay(creditDisplayFromMember(member))
        setCreditsAvailable(member.credits.items[0].creditsAvailable)
    }, [member])

    return (
        <div className="member-portal">
        {/*<div className="your-memberships">Your Memberships</div>*/}
        <div className="member-card">
          <div className="member-card-title">
            <p className="merchant-name">{merchantName}</p>
          </div>
          <div className="member-card-contents">

            <div style={{ margin: "0 auto 20px", width: "fit-content", padding: "0 25px" }}>


              {creditLines(member)}

              <div className="member-data-item">
                <img className="icon" src={iconCopy} style={{marginTop: "-3px"}} alt="icon" />
                <div className="gilroy-regular-regular-normal-black-15-5px">
                  <div className="flex-col-item">
                    <span className="gilroy-regular-regular-normal-black-15-5px">Membership ID: </span>
                    <span >{membershipID}</span>
                  </div>
                </div>
              </div>

              <div className="member-data-item">
                <img className="icon" src={icon1} style={{marginTop: "-3px"}} alt="icon" />
                <div className="gilroy-regular-regular-normal-black-15-5px">
                  <div className="flex-col-item">
                    <span className="gilroy-regular-regular-normal-black-15-5px">Renews On: </span>
                    <span >{member.active? reloadDate : "INACTIVE MEMBERSHIP"}</span>
                  </div>
                </div>
              </div>

              <div className="member-data-item">
                <img className="icon" src={icon2} style={{marginTop: "-3px"}} alt="icon" />
                <div className="gilroy-regular-regular-normal-black-15-5px">
                  <div className="flex-col-item">
                    <span className="gilroy-regular-regular-normal-black-15-5px">Member Since: </span>
                    <span >{memberSinceDate}</span>
                  </div>
                </div>
              </div>

              <div className="member-data-item">
                <img className="icon" src={iconMail} style={{marginTop: "-3px"}} alt="icon" />
                <div className="gilroy-regular-regular-normal-black-15-5px">
                  <div className="flex-col-item">
                    <span className="gilroy-regular-regular-normal-black-15-5px">Membership: </span>
                    <span >{planName}</span>
                  </div>
                </div>
              </div>


            </div>

            {merchantID != "BIGSHAKES11TH" &&
              <button className="change-or-cancel-button" onClick={() => window.open(generateChangeLink(merchantID), "_blank")}>
                Change or Cancel Plan
              </button>
            }
          </div>
        </div>
      </div>
    )
}

export default MemberCard;
