import React from "react";
import "./PortalStyle.css";

import creditLogo from "./img/credit-logo.png"

function CreditsAvailable(props) {
    const {member, credit} = props
    
    var component = member.plan.components.items.filter(component =>
        component.creditTypeComponentId === credit.creditCreditTypeId)[0]
    
    if ((!component.active) || (!member.active)) {
        return
    }
    else if (component.unlimited) {
        return (
            <div className="member-data-item">
            <img className="icon" src={creditLogo} style={{marginTop: "-3px"}} alt="icon" />
            <div className="gilroy-regular-regular-normal-black-15-5px">
            <div className="flex-col-item" >
                <span>&#8734;</span>

                <span className="gilroy-regular-regular-normal-black-15-5px">{` ${credit.creditType.name} Credit(s)`}</span>
            </div>
            </div>
        </div>
        )
    } else {

        return (
            <div className="member-data-item">
            <img className="icon" src={creditLogo} style={{marginTop: "-3px"}} alt="icon" />
            <div className="gilroy-regular-regular-normal-black-15-5px">
            <div className="flex-col-item" >
                <span>{`${credit.creditsAvailable}`}</span>

                <span className="gilroy-regular-regular-normal-black-15-5px">{` ${credit.creditType.name} Credit(s)`}</span>
            </div>
            </div>
        </div>
        )
    }
}

export default CreditsAvailable