import React, { useState } from "react";
import "./Login.css";
import rectangle3 from "./img/rectangle-3@1x.png"
import artboard52X3 from "./img/artboard-5-2x-3@4x.png"
import group1 from "./img/group-1@2x.svg"
import group from "./img/group@2x.svg"
import vector from "./img/vector@1x.png"
import { Auth } from 'aws-amplify'


function Login({updateUser}) {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [code, setCode] = useState('')
    const [step, setStep] = useState(0)
    const [tempUser, setTempUser] = useState(null)
    const [errorMessage, setErrorMessage] = useState("")

    async function logIn(e) {
        e.preventDefault()
        try {
            await Auth.signIn(username, password)
                .then(user => {
                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        console.log('NEW PASSWORD REQUIRED')
                        setTempUser(user)
                        setStep(4)
                    } else {
                        console.log('Password looks good, homes')
                        console.log(user)
                        updateUser(user)
                    }
                    setErrorMessage("")
                })

        } catch (error) {
            console.log('error signing in', error)
            setErrorMessage("Incorrect Username or Password.")
        }

    }

    async function completeNewPassword(e) {
        e.preventDefault()
        try {
            await Auth.completeNewPassword(tempUser, newPassword)
            updateUser(tempUser)
            setErrorMessage("")
        } catch (error) { 
            console.log(error) 
            setErrorMessage("Password not long enough.")
            
        }
    }

    async function forgotPassword(e) {
        e.preventDefault()
        try {
            await Auth.forgotPassword(username)
            setErrorMessage("")
            setStep(3)
        } catch (error) {
            console.log('error signing in', error)
            setErrorMessage("Username/Email not found.")
        }

    }

    async function forgotPasswordSubmit() {
        try {
            await // Collect confirmation code and new password, then
                Auth.forgotPasswordSubmit(username, code, newPassword)
            returnHome()
        } catch (error) {
            console.log('error signing in', error)
            switch(error.code) {
                case 'UserNotFoundException':
                    setErrorMessage('Username/Email not found.')
                    break
                case 'CodeMismatchException':
                    setErrorMessage('Incorrect Verification Code')
                    break
                case 'InvalidPasswordException':
                    setErrorMessage('Password not long enough.')
                    break
                default:
                    setErrorMessage('Invalid parameters provided.')
                    break
            }
        }
    }
    
    function returnHome(){
        setStep(0)
        setErrorMessage("")
    }

    function forgotPasswordStep() {
        setStep(2)
        setErrorMessage("")
    }

    return (
        <div className="login-page">
            <div className="vector-layer">

                <div className="header" >
                    <img className="logo" src={artboard52X3} alt="logo" />
                    <div className="title-block">
                        <div className="title">ChewCrew</div>
                        <div className="subtitle">Restaurant Subscriptions</div>
                    </div>
                </div>

                <div className="login-box" >
                    {step === 0 && (
                        <div>
                            <div className="sign-in">Sign In</div>
                            <form style={{ width: "100%", margin: "0 auto" }}>
                                <label className="field-title">Username/Email*</label>
                                <input className="field-hint border-0-8px-blue-whale-2"
                                    type="text"
                                    name="username"
                                    placeholder="Enter your Username/Email"
                                    onChange={e => setUsername(e.target.value)} />
                                <label className="field-title">Password*</label>
                                <input className="field-hint border-0-8px-blue-whale-2"
                                    type="password"
                                    name="password"
                                    placeholder="Enter your Password"
                                    onChange={e => setPassword(e.target.value)} />
                            </form>
                            <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
                                <button
                                    className="forgot-your-password-button"
                                    onClick={e => forgotPasswordStep()}>
                                    Forgot Your Password?
                                </button>
                            </div>



                            <button onClick={e => logIn(e)} className="transparent-button">
                                Sign In
                            </button>
                            <div className="error-message">
                                {errorMessage}
                            </div>
                        </div>
                    )}
                    {step === 2 && (
                        <div>
                            <div className="sign-in">Reset Password</div>
                            <form style={{ width: "100%", margin: "0 auto" }}>
                                <label className="field-title">Username/Email*</label>
                                <input className="field-hint border-0-8px-blue-whale-2"
                                    type="text"
                                    name="username"
                                    placeholder="Enter your Username/Email"
                                    onChange={e => setUsername(e.target.value)} />
                            </form>
                            <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
                                <button className="forgot-your-password-button" onClick={e => returnHome()}>
                                    Back
                                </button>
                            </div>
                            <button onClick={e => forgotPassword(e)} className="transparent-button">
                                Reset Password
                            </button>
                            <div className="error-message">
                                {errorMessage}
                            </div>
                        </div>
                    )}
                    {step === 3 && (
                        <div>
                            <h2 className="sign-in" style={{ fontSize: "25px" }}>Update Password</h2>
                            <form style={{ width: "100%", margin: "0 auto" }}>
                                <label className="field-title">Username/Email*</label>
                                <input className="field-hint border-0-8px-blue-whale-2"
                                    type="text"
                                    name="username"
                                    placeholder="Enter your Username/Email"
                                    onChange={e => setUsername(e.target.value)} />
                                <label className="field-title">Verification Code*</label>
                                <input className="field-hint border-0-8px-blue-whale-2"
                                    type="text"
                                    name="code"
                                    placeholder="A verification code was sent to your email..."
                                    onChange={e => setCode(e.target.value)} />
                                <label className="field-title">Password*</label>
                                <input className="field-hint border-0-8px-blue-whale-2"
                                    type="password"
                                    name="newPassword"
                                    placeholder="Enter your Password"
                                    onChange={e => setNewPassword(e.target.value)} />
                            </form>
                            <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
                                <button className="forgot-your-password-button" onClick={e => returnHome()}>
                                    Back
                                </button>
                            </div>

                            <button onClick={e => forgotPasswordSubmit(e)} className="transparent-button">
                                Update Password
                            </button>
                            <div className="error-message">
                                {errorMessage}
                            </div>
                        </div>
                    )}

                    {step === 4 && (
                        <div>
                            <h2 className="sign-in" style={{ fontSize: "25px" }}>New Password</h2>
                            <form style={{ width: "100%", margin: "0 auto" }}>
                                <label className="field-title">New Password*</label>
                                <input className="field-hint border-0-8px-blue-whale-2"
                                    type="password"
                                    name="newPassword"
                                    placeholder="Enter Your New Password"
                                    onChange={e => setNewPassword(e.target.value)} />
                            </form>
                            <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
                                <button className="forgot-your-password-button" onClick={e => returnHome()}>
                                    Back
                                </button>
                            </div>

                            <button onClick={e => completeNewPassword(e)} className="transparent-button">
                                Update Password
                            </button>
                            <div className="error-message">
                                {errorMessage}
                            </div>
                        </div>
                    )}
                    {window.innerWidth >= 600 &&
                        <div>
                            <img className="group" src={group} alt="" />
                            <img className="group-1" src={group1} alt="" />
                        </div>
                    }
                </div>
                {/* <img className="vector-new" src={vector} alt=""/> */}

            </div>
        </div>
    );
}

export default Login;
