import React, {useEffect, useState} from 'react'
import {Amplify, API, Auth, Hub, graphqlOperation} from 'aws-amplify'
import * as customQueries from './graphql/customQueries'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';
import awsExports from "./aws-exports"
import logo from './logo.svg';
import './App.css';
import Login from './Login/Login';
import Portal from './portal/Portal';

Amplify.configure(awsExports)

function App() {

  const [user, updateUser] = useState(null);
  
  const [members, setMembers] = useState([])

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => updateUser(user))
      .catch(() => console.log('No signed in user.'));
    Hub.listen('auth', data => {
      switch (data.payload.event) {
        case 'signIn':
          return updateUser(data.payload.data);
        case 'signOut':
          return updateUser(null);
      }
    });
  }, [])
  
  useEffect(() => {
    fetchMembers()
  }, [])

  async function fetchMembers() {
    try {
      const variables = {
        username: "kyle.leland2015@gmail.com"
      }
      const memberData = await API.graphql({
        query: customQueries.membersByUsername,
        variables: variables,
        authMode: GRAPHQL_AUTH_MODE.API_KEY
      })
      const members = memberData.data.membersByUsername.items
      setMembers(members)
      console.log(members)

    } catch (err) {
      console.log(err)
    }
  }

  if (user) {
    return (
      <div className="App" style={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#FFF',
        overflowY: 'scroll'
      }}>
        <Portal user={user} />
      </div >
    )
  }

  return (
    <div className="App" style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundColor: '#F7F7F7',
      overflowY: 'scroll'
    }}>
        <Login updateUser={updateUser}/>
    </div >
  )
}

export default App;
