import React, { useState, useEffect } from "react";
import "./PortalStyle.css";
import icon from "./img/icon@2x.svg"
import iconCopy from "./img/icon-copy@2x.svg"
import icon1 from "./img/icon-2@2x.svg"
import icon2 from "./img/icon-1@2x.svg"
import iconMail from "./img/icon-email@2x.svg"
import creditLogo from "./img/credit-logo.png"
import moment from 'moment'
import { Spinner } from "react-bootstrap";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';
import * as customQueries from '../graphql/customQueries'
import CreditsAvailable from "./CreditsAvailable";
import MemberCard from "./MemberCard";




import { API } from 'aws-amplify';


function Portal(props) {

  const artboard52X5 = "https://anima-uploads.s3.amazonaws.com/projects/6293d6af26f8364d4373cf96/releases/6293d79a9a9d18d6b6125f96/img/artboard-5-2x-5@4x.png"


  const height = window.innerHeight;
  const width = window.innerWidth;

  const { user } = props
  const [merchants, setMerchants] = useState([])
  const [memberships, setMemberships] = useState([])

  const [members, setMembers] = useState([])

  async function fetchMembers(username) {
    try {
      const variables = {
        username: username
      }
      const memberData = await API.graphql({
        query: customQueries.membersByUsername,
        variables: variables,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      })
      const updatedMembers = memberData.data.membersByUsername.items
      setMembers(updatedMembers)
      console.log(updatedMembers)
      return updatedMembers

    } catch (err) {
      console.log(err)
      return members
    }
  }



  async function buildMember(username) {
    const membersFromAPI = await fetchMembers(username)
    console.log(membersFromAPI)
  }

  const link = window.location.href
  const splitUrl = link.split("/")
  const linkEnd = splitUrl[splitUrl.length - 1]

  function generateReload(newMealDOM) {
    const month = moment().month() + 1
    const day = moment().date()
    const year = moment().year()
    console.log(`newMealDOM: ${newMealDOM}`)
    console.log(`month: ${month}`)
    console.log(`day: ${day}`)
    console.log(`year: ${year}`)
    let end = `${newMealDOM}th of month`

    if (newMealDOM <= day) {
      if (month === 12) {
        end = moment(`${1}-${newMealDOM}-${year + 1}`, "MM-DD-YYYY").format("MMMM Do")
      } else if (moment(`${month + 1}-${newMealDOM}-${year}`, "MM-DD-YYYY").month() === month) {
        end = moment(`${month + 1}-${newMealDOM}-${year}`, "MM-DD-YYYY").format("MMMM Do")
      } else {
        end = moment(`${month + 1}-${year}`, "MM-YYYY").endOf('month').format("MMMM Do")
      }
    } else {
      if (moment(`${month}-${newMealDOM}-${year}`, "MM-DD-YYYY").month() + 1 === month) {
        end = moment(`${month}-${newMealDOM}-${year}`, "MM-DD-YYYY").format("MMMM Do")
      } else {
        end = moment(`${month}-${year}`, "MM-YYYY").endOf('month').format("MMMM Do")
      }
    }
    return `${end}`
  }

  function generateMemberSince(createdAt) {
    const str = moment(createdAt, "YYYY-MM-DDTHH:mm:ss.SSSZ").format('MMM YYYY')
    return `${str}`
  }

  function generatePlanTier(planTier) {
    if (planTier === 3) {
      return 'Gold'
    } else if (planTier === 2) {
      return 'Silver'
    } else {
      return 'Bronze'
    }
  }

  function generateChangeLink(merchantID) {
    switch (merchantID) {
      // Chevron
      case 'Q7Z5A19D1A7D1':
        return "https://form.jotform.com/213050471551142"
      // Shell
      case 'WQZS2Z0QX1CM1':
        return "https://form.jotform.com/213050454188149"
      // Braxton's
      case 'AGZVK2WRCKQM1':
        return "https://form.jotform.com/213048951065051"
      // Test
      case 'TestMerchant':
        return "https://form.jotform.com/213048951065051"
      // Nourish
      case 'NOURISH':
        return "https://form.jotform.com/213145600160137"
      case 'BRAXTONSCAFE':
        return "https://form.jotform.com/220387977002155"
      // Big Shakes
      case 'BIGSHAKES':
        return "https://form.jotform.com/220820742628151"
      // Array of Sun
      // Bahama Golf
      case 'BAHAMAGOLF':
        return "https://form.jotform.com/221110614842140"
      case 'TOSSITUP':
        return "https://form.jotform.com/213161297814153"
      case 'VERITABLE':
        return "https://form.jotform.com/221358951057156"
      case 'ARRAYOFSUN':
        return "https://form.jotform.com/221358756885168"
      case 'BIGSHAKES11TH':
        return "https://thechewcrew.co/bigshakeshotchicken"
      case 'DINGHY':
        return "https://form.jotform.com/231643512704146"
      case 'MVLIQUIDATION':
        return "https://form.jotform.com/232016988382160"
      case 'FORGOTTENSTAR':
        return "https://form.jotform.com/232215640993154"
      case 'RISECAFE':
        return "https://form.jotform.com/232420486599162"
      case 'SOMMELIER':
        return "https://form.jotform.com/232481113389153"
      default:
        return "https://form.jotform.com/213048951065051"
    }
  }

  function unlimited(merchantID) {
    switch(merchantID) {
      case 'VERITABLE':
        return true
      case 'BAHAMAGOLF':
        return true
      case 'TestMerchant':
        return false
      default:
        return false
    }
  }

  function creditDisplayFromMember(member) {
    var displayString = ``
    member.credits.items.forEach((credit) => {
      var component = member.plan.components.items.filter(component =>
        component.creditTypeComponentId === credit.creditCreditTypeId)[0]
      if (component.unlimited === true) {
        displayString+=`Unlimited ${credit.creditType.name} Credit(s)\n`
      } else {
        displayString+=`${credit.creditsAvailable} ${credit.creditType.name} Credits \n`
      }
    })
    return displayString
  }

  function creditLines(member) {
    return member.credits.items.map((credit,index) => <CreditsAvailable key={index} member={member} credit={credit}/>)
  }


  useEffect(() => {
    buildMember(user.username)
  }, [user])

  if (members.length !== 0) {

    var member = members[0]
    const merchantName = member.merchant.name
    const username = member.username
    const membershipID = member.membershipID
    const reloadDate = generateReload(member.signUpDOM)
    const memberSinceDate = generateMemberSince(member.createdAt)
    const planName = member.plan.name
    const merchantID = member.merchant.merchantID
    const creditDisplay = creditDisplayFromMember(member)

    const creditsAvailable = member.credits.items[0].creditsAvailable



    return (

      <div className="display" >
        <div className="logged-in-header" >
          {/*<img className="artboard-52x-5" src={artboard52X5} alt="missing upload from anima" /> */}
          <div className="title-block" style={{backgroundImage: artboard52X5}}>
            <div className="title-loggedin">{merchantName} {window.innerWidth<600 && <br/>}Memberships</div>
            <img className="artboard-52x-5" src={artboard52X5} alt="missing upload from anima" /> 


            <div className="subtitle-loggedin">Powered by ChewCrew</div>
          </div>
        </div>

        <div className="portal">
        {window.innerWidth >= 600 &&
          <div className="left-dots-container">
            <img
              className="dots-1"
              src="https://anima-uploads.s3.amazonaws.com/projects/6293d6af26f8364d4373cf96/releases/6293d79a9a9d18d6b6125f96/img/group-2@2x.svg"
              alt="missing upload from anima"
            />
            <img
              className="dots-2"
              src="https://anima-uploads.s3.amazonaws.com/projects/6293d6af26f8364d4373cf96/releases/6293d79a9a9d18d6b6125f96/img/group-3@2x.svg"
              alt="missing upload from anima"
            />
          </div>
        }

        <div className="cards-stack">
            {members.map((member_info,index) => {
              return <MemberCard key={index} member={member_info}/>
            })}
        </div>
          

          {window.innerWidth >= 600 &&
            <div className="right-dots-container">
              <img
                className="dots-3"
                src="https://anima-uploads.s3.amazonaws.com/projects/6293d6af26f8364d4373cf96/releases/6293d79a9a9d18d6b6125f96/img/group-1@2x.svg"
                alt="missing upload from anima"
              />
              <img
                className="dots-4"
                src="https://anima-uploads.s3.amazonaws.com/projects/6293d6af26f8364d4373cf96/releases/6293d79a9a9d18d6b6125f96/img/group@2x.svg"
                alt="missing upload from anima"
              />
            </div>
          }
        </div>
        <div className="customer-support">
          <p className="customer-support-co">
            <span className="span0">Customer Support?</span>
            <span className="span1"> </span>
            <span className="span2">Contact us at support@chewcrew.co</span>
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="display" >
      <div className="logged-in-header">
        <img className="artboard-52x-5" src={artboard52X5} alt="missing upload from anima" />
        <h1 className="title-loggedin">ChewCrew</h1>
      </div>
      <div className="portal">
        {window.innerWidth >= 600 &&
          <div className="left-dots-container">
            <img
              className="dots-1"
              src="https://anima-uploads.s3.amazonaws.com/projects/6293d6af26f8364d4373cf96/releases/6293d79a9a9d18d6b6125f96/img/group-2@2x.svg"
              alt="missing upload from anima"
            />
            <img
              className="dots-2"
              src="https://anima-uploads.s3.amazonaws.com/projects/6293d6af26f8364d4373cf96/releases/6293d79a9a9d18d6b6125f96/img/group-3@2x.svg"
              alt="missing upload from anima"
            />
          </div>
        }

        <Spinner animation="border" size="lg" variant="primary" style={{ margin: "0 auto" }} />


        {window.innerWidth >= 600 &&
          <div className="right-dots-container">
            <img
              className="dots-3"
              src="https://anima-uploads.s3.amazonaws.com/projects/6293d6af26f8364d4373cf96/releases/6293d79a9a9d18d6b6125f96/img/group-1@2x.svg"
              alt="missing upload from anima"
            />
            <img
              className="dots-4"
              src="https://anima-uploads.s3.amazonaws.com/projects/6293d6af26f8364d4373cf96/releases/6293d79a9a9d18d6b6125f96/img/group@2x.svg"
              alt="missing upload from anima"
            />
          </div>
        }
      </div>
      <div className="customer-support">
        <p className="customer-support-co">
          <span className="span0">Customer Support?</span>
          <span className="span1"> </span>
          <span className="span2">Contact us at support@chewcrew.co</span>
        </p>
      </div>
    </div>)

}

export default Portal;
