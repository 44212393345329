/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "apie3984401",
            "endpoint": "https://6kkecgt2qc.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://4b363ansang5dgr6irtqfzu4bu.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-k5pu5aqfaneudf5atc7pytfusy",
    "aws_cognito_identity_pool_id": "us-east-2:76df9b73-e375-4d16-a4ad-597dbb36abbf",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_XB3cVo9QJ",
    "aws_user_pools_web_client_id": "5ukpbh5g7ts134mph8qld44l1i",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
