/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const membersByUsername = /* GraphQL */ `
  query MembersByUsername(
    $username: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    membersByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        email
        credits {
          items {
            id
            creditType {
              id
              name
              discount
              discountType
              discountLevel
              merchantUUID
              merchantCreditTypesId
            }
            creditsAvailable
            memberCreditsId
            creditCreditTypeId
          }
          nextToken
        }
        membershipID
        
        signUpDOW
        signUpDOM
        merchant {
          id
          username
          verification
          name
          email
          merchantID
        }
        merchantUUID
        plan {
          id
          tier
          price
          priceID
          name
          color
          components {
            items {
              active
              id
              creditsPerPeriod
              recurrence
              redemptionLimit
              unlimited
              planComponentsId
              creditTypeComponentId
            }
            nextToken
          }
          merchantPlansId
        }
        active
        activeUpdatedAt
        createdAt
        updatedAt
        merchantMembersId
        planMembersId
      }
      nextToken
    }
  }
`;
